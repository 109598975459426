<template>
  <header class="sky__header">
    <desktop-top-bar :configuration="configuration" />
    <mobile-top-bar />
    <menu-wrapper />
  </header>
</template>

<script>
import DesktopTopBar from './DesktopTopBar'
import MobileTopBar from './MobileTopBar'
import MenuWrapper from './menu/MenuWrapper'

export default {
  components: {
    DesktopTopBar,
    MobileTopBar,
    MenuWrapper
  },
  props: {
    configuration: {
      type: Object,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.sky__header {
  @apply bg-white z-20 relative;
}
</style>
