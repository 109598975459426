<template>
  <div ref="desktop-top" class="sky__desktop-top">
    <div class="container mx-auto text-white py-2 text-xs flex justify-between">
      <div>
        Besoin d'un conseil? Appelez-nous au +33 (0)3 66 72 85 22
      </div>
      <div v-if="configuration.general_offer">
        <span v-html="transformedBanner"></span>
        <!-- <strong>-10% COMPLEMENTAIRE</strong> SUR TOUT LE SITE AVANT NOËL AVEC LE CODE <strong>EXTRA10</strong> -->
          <!--  <strong>-10% COMPLEMENTAIRE</strong> SUR TOUT LE SITE PENDANT LES SOLDES AVEC LE CODE <strong>SOLDES10</strong>-->
      </div>
      <div>
        Livraison gratuite en point relais dès 89 €
        <span v-if="$store.getters['auth/isLoggedIn']">
          - Bonjour <nuxt-link to="/mon-compte">{{ $store.state.auth.displayName }}</nuxt-link>
          - <a href="#" class="underline" @click.prevent="$store.dispatch('auth/disconnect')">Déconnexion</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { marked } from 'marked'
export default {
  props: {
    configuration: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      banner: null
    }
  },
  computed: {
    transformedBanner () {
      if (!this.configuration?.general_offer) {
        return null
      }
      return marked(this.configuration.general_offer)
    }
  }
}
</script>

<style lang="scss" scoped>
  .sky__desktop-top {
    @apply bg-sky_blue hidden;
    @screen lg {
      @apply block;
    }
  }
</style>
